<template>
  <v-card>
    <v-card-actions>
      <v-btn color="primary" @click="imprimir(nota.chave)"><v-icon>print</v-icon></v-btn>
    </v-card-actions>
    <div id="nota_fiscal" ref="nota_fiscal" style="width: 1000px">
      <div class="box">
        <div class="row">
          <div class="col-p-10">
            <div class="campo-box bl0 bt0">
              <div class="titulo">RECEBEMOS DE {{ nota.empresa.fantasia }}</div>
              <div class="conteudo">&nbsp;</div>
            </div>
          </div>
          <div class="col-p-2">
            <div class="campo-box no-border alinha-centro">
              <div class="titulo">NF-e</div>
              <div class="conteudo">N&ordm; {{ nota.numero | formatarPadZero(9) }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-p-4">
            <div class="campo-box no-border">
              <div class="titulo">Data Recebimento</div>
              <div class="conteudo">&nbsp;</div>
            </div>
          </div>
          <div class="col-p-6">
            <div class="campo-box bt0 bb0">
              <div class="titulo">Identificação e assinatura do recebedor</div>
              <div class="conteudo">&nbsp;</div>
            </div>
          </div>
          <div class="col-p-2">
            <div class="campo-box no-border alinha-centro">
              <div class="titulo">Série {{ nota.serie | formatarPadZero(3) }}</div>
            </div>
          </div>
        </div>
      </div>

      <hr style="border: 1px dashed; margin-top: 5px" />

      <div class="box" style="margin-top: 5px">
        <div class="box col-p-2 no-border alinha-centro" style="padding: 21px 10px">
          <div class="row">
            <img src="../../assets/nfe.jpg" style="width: 134px; height: 135px" />
          </div>
        </div>
        <div class="col-p-3 box alinha-centro bt0 bb0" style="height: 183px; padding: 50px 10px 10px 10px">
          <div class="row">
            <strong>{{ nota.empresa.razao }}</strong>
          </div>
          <div class="row">{{ nota.empresa.logradouro }}, {{ nota.empresa.numero }}</div>
          <div class="row">{{ nota.empresa.bairro }} - {{ nota.empresa.cidade }}-{{ nota.empresa.uf | codigoParaUf }}</div>
          <div class="row">Telefone - {{ nota.empresa.telefone | formatarTelefone }} CEP {{ nota.empresa.cep | formatarCep }}</div>
        </div>
        <div class="col-p-2 box alinha-centro no-border" style="padding: 2.5px 5px">
          <div class="row">
            <div class="campo-box no-border no-mp">
              <div class="titulo"><strong class="">DANFE</strong><br /></div>
              <div class="conteudo">
                <p>Documento Auxiliar da Nota Fiscal Eletrônica</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-p-6">
              <div class="campo-box no-mp no-border">
                <div class="titulo alinha-esquerda" style="margin-left: 20px">
                  0- Entrada<br />
                  1- Sa&iacute;da<br />
                </div>
              </div>
            </div>
            <div class="col-p-3 alinha-esquerda">
              <div class="campo-box pb0 pr0 mr0" style="">
                <div class="conteudo">
                  {{ nota.tipo }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="campo-box no-border bold no-mp">
              <div class="titulo">
                N&ordm; {{ nota.numero | formatarPadZero(9) }}<br />
                Série: {{ nota.serie | formatarPadZero(3) }}
              </div>
              <div class="conteudo">Folha 1/1</div>
            </div>
          </div>
        </div>
        <div class="col-p-5 box no-border">
          <div class="row">
            <div class="campo-box bt0 br0 bb0 no-mp">
              <div class="titulo alinha-centro">Controle do Fisco</div>
              <div class="conteudo alinha-centro">
                <svg id="codigo_barras" style="width: 400px; height: 61px"></svg>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="campo-box alinha-centro bb0 br0 no-mp">
              <div class="titulo">Chave de Acesso</div>
              <div class="conteudo">
                {{ nota.chave | formatarChave }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="campo-box alinha-centro bb0 br0 no-mp">
              <div class="titulo">Consulta de autenticidade no portal nacional da NF-e</div>
              <div class="conteudo"><a href="www.nfe.fazenda.gov.br/portal">www.nfe.fazenda.gov.br/portal</a> ou no site da Sefaz autorizadora</div>
            </div>
          </div>
        </div>
        <div class="col-p-7">
          <div class="row">
            <div class="campo-box bb0 bl0 no-mp">
              <div class="titulo">Natureza da Operação</div>
              <div class="conteudo">
                {{ nota.natureza_operacao }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-5">
          <div class="row">
            <div class="campo-box alinha-centro bl0 bb0 br0 no-mp">
              <div class="titulo">Número de protocolo de autorização de uso da NF-e</div>
              <div class="conteudo">
                <span v-if="!nota.autorizacao">NFe sem Autorização de Uso da SEFAZ</span>
                <span v-else>{{ nota.autorizacao.protocolo }} {{ nota.autorizacao.data | formatarData }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-4">
          <div class="row">
            <div class="campo-box bl0 bb0">
              <div class="titulo">Inscrição Estadual</div>
              <div class="conteudo">
                {{ nota.empresa.ie }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-4">
          <div class="row">
            <div class="campo-box bl0 bb0">
              <div class="titulo">Inscr.est. do subst.trib.</div>
              <div class="conteudo">&nbsp;</div>
            </div>
          </div>
        </div>
        <div class="col-p-4">
          <div class="row">
            <div class="campo-box bl0 bb0 br0">
              <div class="titulo">CNPJ</div>
              <div class="conteudo">
                {{ nota.empresa.cnpj | formatarCnpj }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr style="border: 0px; margin-top: 5px" />
      <h4>Destinatário/Remetente</h4>

      <div class="box">
        <div class="col-p-6">
          <div class="row">
            <div class="campo-box no-border">
              <div class="titulo">Nome / Razão Social</div>
              <div class="conteudo">
                {{ nota.cliente.razaoSocialNome }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">CNPJ/CPF</div>
              <div class="conteudo">
                {{ nota.cliente.cnpjCpf | formatarCnpj }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Inscrição Estadual</div>
              <div class="conteudo">
                {{ nota.cliente.inscricao }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Data emissão</div>
              <div class="conteudo">
                {{ nota.data_emissao | formatarData }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-p-6">
          <div class="row">
            <div class="campo-box bl0 bb0 br0">
              <div class="titulo">Endereço</div>
              <div class="conteudo">{{ nota.cliente.logradouro }}, {{ nota.cliente.numero }}</div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Bairro</div>
              <div class="conteudo">
                {{ nota.cliente.bairro }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">CEP</div>
              <div class="conteudo">
                {{ nota.cliente.cep | formatarCep }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Data Saída</div>
              <div class="conteudo">
                {{ nota.data_emissao | formatarData }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-p-6">
          <div class="row">
            <div class="campo-box bl0 bb0 br0">
              <div class="titulo">Município</div>
              <div class="conteudo">
                {{ nota.cliente.cidade }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Fone/Fax</div>
              <div class="conteudo">
                {{ nota.cliente.telefone | formatarTelefone }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">UF</div>
              <div class="conteudo">
                {{ nota.cliente.estado }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Hora saída</div>
              <div class="conteudo">
                {{ nota.data_emissao | formatarHora }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr v-if="nota.forma_pagamento !== '0'" style="border: 0px; margin-top: 5px" />
      <h4 v-if="nota.forma_pagamento !== '0'">Faturas</h4>

      <div class="box no-border" v-if="nota.forma_pagamento !== '0'">
        <div class="col-p-3" v-for="parcela in nota.parcelas_pagamento" :key="parcela.parcela">
          <div class="box" style="margin: 0px -5px">
            <div class="col-p-4">
              <div class="campo-box no-border">
                <div class="titulo">Número</div>
                <div class="conteudo">{{ nota.numero }}-{{ parcela.parcela }}</div>
              </div>
            </div>
            <div class="col-p-4">
              <div class="campo-box no-border">
                <div class="titulo">Vencimento</div>
                <div class="conteudo">
                  {{ parcela.vencimento }}
                </div>
              </div>
            </div>
            <div class="col-p-4">
              <div class="campo-box no-border">
                <div class="titulo">Valor</div>
                <div class="conteudo">
                  {{ parcela.valor | formatarValor }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr style="border: 0px; margin-top: 5px" />
      <h4>Cálculo do Imposto</h4>

      <div class="box">
        <div class="col-p-3">
          <div class="row">
            <div class="campo-box no-border">
              <div class="titulo">Base de cálculo do ICMS</div>
              <div class="conteudo">
                {{ nota.total_bc_icms | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Valor do ICMS</div>
              <div class="conteudo">
                {{ nota.total_icms | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-3">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Base de cálculo do ICMS Subst.</div>
              <div class="conteudo">
                {{ nota.total_bc_icms_st | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Valor do ICMS Subst.</div>
              <div class="conteudo">
                {{ nota.total_icms_st | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Valor total dos produtos</div>
              <div class="conteudo">
                {{ nota.total_produtos | formatarValor }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bl0 bb0 br0">
              <div class="titulo">Valor do frete</div>
              <div class="conteudo">
                {{ nota.frete | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Valor do seguro</div>
              <div class="conteudo">
                {{ nota.seguro | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Desconto</div>
              <div class="conteudo">
                {{ nota.desconto | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Outras despesas acessórias</div>
              <div class="conteudo">
                {{ nota.outras_despesas | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Valor do IPI</div>
              <div class="conteudo">
                {{ nota.total_ipi | formatarValor }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bb0 br0">
              <div class="titulo">Valor total da nota</div>
              <div class="conteudo">
                {{ nota.total_nota | formatarValor }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr style="border: 0px; margin-top: 5px" />
      <h4>Itens da Nota Fiscal</h4>

      <div class="box" style="height: 400px">
        <table class="tabela no-mp">
          <tr class="tr">
            <th class="th alinha-esquerda">Código</th>
            <th class="th alinha-esquerda" colspan="3">Descrição</th>
            <th class="th alinha-centro">NCM</th>
            <th class="th alinha-centro">CST</th>
            <th class="th alinha-centro">CFOP</th>
            <th class="th alinha-centro">Un</th>
            <th class="th alinha-direita">Qtd</th>
            <th class="th alinha-direita">Vlr Un.</th>
            <th class="th alinha-direita">Vlr Tot.</th>
            <th class="th alinha-direita">BC ICMS</th>
            <th class="th alinha-direita">Vlr. ICMS</th>
            <th class="th alinha-direita">Vlr. IPI</th>
            <th class="th alinha-direita">% ICMS</th>
            <th class="th alinha-direita">% IPI</th>
          </tr>
          <tr class="tr" v-for="produto in nota.produtos" :key="produto.sequencia">
            <td class="td alinha-esquerda">{{ produto.codigo }}</td>
            <td class="td alinha-esquerda" colspan="3">{{ produto.descricao }}</td>
            <td class="td alinha-centro">{{ produto.ncm }}</td>
            <td class="td alinha-centro">{{ produto.cst_icms }}</td>
            <td class="td alinha-centro">{{ produto.cfop }}</td>
            <td class="td alinha-centro">{{ produto.unidade }}</td>
            <td class="td alinha-direita">{{ produto.quantidade | formatarValor }}</td>
            <td class="td alinha-direita">{{ produto.valor | formatarValor }}</td>
            <td class="td alinha-direita">{{ produto.subtotal | formatarValor }}</td>
            <td class="td alinha-direita">{{ produto.bc_icms | formatarValor }}</td>
            <td class="td alinha-direita">{{ produto.valor_icms | formatarValor }}</td>
            <td class="td alinha-direita">{{ produto.valor_ipi | formatarValor }}</td>
            <td class="td alinha-direita">{{ produto.aliq_icms | formatarValor }}</td>
            <td class="td alinha-direita">{{ produto.aliq_ipi | formatarValor }}</td>
          </tr>
        </table>
      </div>

      <hr style="border: 0px; margin-top: 5px" />
      <h4>Cálculo do ISSQN</h4>

      <div class="box">
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box no-border">
              <div class="titulo">Inscrição Municipal</div>
              <div class="conteudo">&nbsp;</div>
            </div>
          </div>
        </div>
        <div class="col-p-4">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Valor total dos serviços</div>
              <div class="conteudo">0,00</div>
            </div>
          </div>
        </div>
        <div class="col-p-4">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Base de cálculo do ISSQN</div>
              <div class="conteudo">0,00</div>
            </div>
          </div>
        </div>
        <div class="col-p-2">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">Valor do ISSQN</div>
              <div class="conteudo">0,00</div>
            </div>
          </div>
        </div>
      </div>

      <hr style="border: 0px; margin-top: 5px" />
      <h4>Dados Adicionais</h4>

      <div class="box">
        <div class="col-p-8">
          <div class="row">
            <div class="campo-box no-border">
              <div class="titulo">INFORMAÇÕES COMPLEMENTARES</div>
              <div class="conteudo">
                {{ nota.empresa.configuracao_tributaria.info_complementar }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-p-4">
          <div class="row">
            <div class="campo-box bt0 bb0 br0">
              <div class="titulo">RESERVADO AO FISCO</div>
              <div class="conteudo">
                &nbsp;<br />
                &nbsp;<br />
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="editor"></div>
  </v-card>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { mapState } from 'vuex';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import constantes from '@/util/constantes';

export default {
  data: () => ({}),

  computed: {
    ...mapState('nota', {
      nota: 'notaAtual',
    }),
  },

  methods: {
    imprimir(chave) {
      // let doc = new jsPDF();

      //  let codigoHTML = document.getElementById('nota_fiscal');

      //let css = document.getElementsByTagName('STYLE')[4];

      // let print = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
      // print.document.write(codigoHTML);
      // print.document.write('<br/>');
      // print.document.write(css);
      // print.document.close();
      // print.focus();
      // print.print();
      // print.close();
      //window.print();

      // let doc = new jsPDF('portrait', 'pt', 'a4');
      // let data = new Date();
      // let margins = {
      //     top: 40,
      //     bottom: 60,
      //     left: 40,
      //     width: 1000
      // };
      // doc.fromHTML(codigoHTML, margins.left, margins.top, { pagesplit: true }, function(dispose){
      //     doc.save("Relatorio.pdf");
      // });

      let conteudo = document.getElementById('nota_fiscal');
      html2canvas(conteudo)
        .then((canvas) => {
          var pdf = new jsPDF('p', 'pt', 'letter');

          for (var i = 0; i <= conteudo.clientHeight / 1335; i++) {
            var srcImg = canvas;
            var sX = 0;
            var sY = 1335 * i;
            var sWidth = 1200;
            var sHeight = 1335;
            var dX = 0;
            var dY = 0;
            var dWidth = 1200;
            var dHeight = 1335;

            window.onePageCanvas = document.createElement('canvas');
            onePageCanvas.setAttribute('width', 1200);
            onePageCanvas.setAttribute('height', 1335);
            var ctx = onePageCanvas.getContext('2d');
            ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

            var canvasDataURL = onePageCanvas.toDataURL('image/png', 1.0);

            var width = onePageCanvas.width;
            var height = onePageCanvas.clientHeight;

            if (i > 0) {
              pdf.addPage(612, 791);
            }
            pdf.setPage(i + 1);
            pdf.addImage(canvasDataURL, 'PNG', 20, 40, width * 0.5, height * 0.62);
          }
          pdf.save('NFe' + chave + '.pdf');
        })
        .catch((err) => {
          this.$store.commit('setMensagemErro', error);
        });

      //   let nota = this.$store.state.nota.notaAtual;
      //   let htmlNota = document.getElementById('nota_fiscal').innerHTML;

      //   this.$store.dispatch('nota/imprimirNota', {html: window.btoa(unescape(encodeURIComponent(htmlNota))), chave: nota.chave});
    },

    gerarCodigo() {
      const nota = this.$store.state.nota.notaAtual;
      JsBarcode('#codigo_barras', nota.chave, { displayValue: false, height: 40 }); //format: 'CODE128'
    },
  },

  mounted() {
    this.gerarCodigo();
  },
};
</script>

<style lang="css" scoped>
@import url('../../assets/reports.css');
</style>
